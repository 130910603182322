<template>
  <div class="app-container">
    <div class="table-container">
      <el-form ref="form" :rules="rules" :model="form" label-width="100px">
        <el-form-item label="手机号" prop="tel">
          <el-input
            clearable
            placeholder="请输入手机号"
            v-model="form.tel"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input clearable placeholder="请输入验证码" v-model="form.code"></el-input>
          <!-- <el-button :disabled="isClick" class="get-code" @click="getCode">{{tips}}</el-button> -->
          <getVerificationCode :mobile="form.tel" class="get-code" />
        </el-form-item>
        <el-form-item label="密码" prop="psd">
          <el-input
            clearable
            placeholder="请输入密码"
            v-model="form.psd"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="submitLoading" @click="onSubmit">提交</el-button>
          <el-button @click="onBack">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { register } from '@/api/system/user';
import { validatePhoneSimple } from '@/common/validator';
import getVerificationCode from '@/components/getVerificationCode';
export default {
  name: 'personal-info',
  components: { getVerificationCode },
  data() {
    return {
      isClick: false, // 获取验证码按钮是否可以点击
      t: 0,
      tips: '获取验证码',
      form: {
        tel: '',
        code: '',
        psd: '',
      },
      submitLoading: false,
      rules: {
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhoneSimple, trigger: 'blur' },
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        psd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
    };
  },
  methods: {
    // getCode() {
    //   if (!this.form.tel || !/^1\d{10}$/.test(this.form.tel)) {
    //     this.$message.error('请正确输入手机号');
    //     return;
    //   }
    //   this.isClick = true;
    //   let sec = 59;
    //   this.t = setInterval(() => {
    //     this.tips = `${sec}s`;
    //     if (!sec || sec < 1) {
    //       clearInterval(this.t);
    //       this.tips = '获取验证码';
    //       this.isClick = false;
    //     }
    //     sec--;
    //   }, 1000);
    //   getCode(this.form.tel).then(response => {
    //     this.$message.success('发送成功');
    //   });
    // },
    onSubmit() {
      const { tel, code, psd } = this.form;
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true;
          register(tel, code, psd)
            .then(() => {
              this.$message.success('保存成功');
              this.$router.back();
            })
            .finally(() => {
              this.submitLoading = false;
            });
        }
      });
    },
    onBack() {
      this.$router.back();
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.table-container {
  .get-code {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 16px;
    user-select: none;
    border: none;
    height: 36px;
    line-height: 36px;
    padding: 0;
    background: transparent;
  }
}
</style>

